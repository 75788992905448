.product {
    display: flex;
    flex-direction: column;
    border: 1px solid var(--tg-theme-button-color);
    padding: 15px;
    width: 170px;
}


.img {
    width: 100%;
    background: lightgray;
    height: 100px;
    margin: 0 auto;
    background: var(--tg-theme-button-color);
}

.description {
    font-size: 0.8em;
}

.add-btn {
    width: 100%;
}
